<template>
    <div class="footer">
        <!-- <p>
            Copyright &copy; 2021 College of Intelligence and Computing, Tianjin
            University All rights reserved.
        </p> -->
        <p>
            For double blind peer review.
        </p>
    </div>
</template>

<script>
export default {
    name: "Footer",
    data(){
        return{

        };
    }
}
</script>

<style scoped>
.footer {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: rgb(51, 51, 51);
  position: relative;
  color: white;
}
</style>